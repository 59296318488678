@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Assistant&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200&display=swap");




html,
body {
  height: 100%;
}


::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #275350;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #24243a;
}

@layer utilities {
  .footer_links {
    color: white;
  }
  .footer_links:hover {
    color: #fdc800;
  }
  .font-nunito {
    font-family: "Nunito", sans-serif;
  }
  .font-roboto {
    font-family: "Roboto", sans-serif;
  }
  .font-gluten {
    font-family: "Gluten", cursive;
  }
  .font-poppins {
    font-family: "Poppins", sans-serif;
  }

  .font-assistant {
    font-family: "Assistant", sans-serif;
  }
  .font-inter {
    font-family: "Inter", sans-serif;
  }
  .creditCardText {
    @apply font-roboto font-normal text-lg text-[#4F5B76] leading-4 focus:outline-none focus:ring focus:border-[#275350];
  }
}

/* media queries */
@media screen and (max-width: 992px) {
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #275350;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #24243a;
  }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #275350;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #24243a;
  }
}

.subscribe-input {
  outline: none;
}

.testimonial-image {
  border-radius: 80%;
  min-width: 180px;
  min-height: 180px;
}

.subscribe-btn {
  min-width: fit-content;
}




